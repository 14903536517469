import gql from 'graphql-tag';

const loggedUserQuery = gql`
  query {
    user {
      firstName
    }
  }
`;

export default loggedUserQuery;
