import { VIDEO_ATTRIBUTES, VIDEO_FUNCTIONS } from './config';

const mapVideoAttributes = attributes => {
  const attributesArray = Object.keys(VIDEO_ATTRIBUTES);
  const mappedAttributes = {};

  attributesArray.forEach(attribute => {
    mappedAttributes[attribute] = attributes.includes(
      VIDEO_ATTRIBUTES[attribute]
    );
  });

  return mappedAttributes;
};

export default function mapVideoFuncionalities(attributes) {
  const mappedAttributes = mapVideoAttributes(attributes);
  const videoFunctionalities = Object.keys(VIDEO_FUNCTIONS);
  let funcionalities = {};

  videoFunctionalities.forEach(funcionality => {
    const hasFuncionality = mappedAttributes[funcionality];

    if (hasFuncionality) {
      funcionalities = {
        ...funcionalities,
        ...VIDEO_FUNCTIONS[funcionality]
      };
    }
  });

  return funcionalities;
}
