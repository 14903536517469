export default function videoNative(wrapperVideo) {
  const placeholder = wrapperVideo.querySelector('.img');
  const video = wrapperVideo.querySelector('.video');
  const playPromise = video.play();

  if (playPromise !== undefined) {
    playPromise
      .then(() => {
        if (placeholder) {
          placeholder.classList.add('hide');
        }
      })
      .catch(error => {
        throw error;
      });
  }
}
