// Import swiper min css to bundle it
import 'swiper/dist/css/swiper.min.css';
import Swiper from 'swiper';

import screens from '@loggi/ui/components/utils/screens';

const DEFAULT_OPTIONS = {
  autoplay: {
    delay: 4500
  },
  breakpoints: {
    [screens.sizes.lg]: {
      loop: true,
      pagination: {
        clickable: true,
        el: '[data-slide="pagination"]'
      },
      touchRatio: 1
    }
  },
  lazy: {
    loadPrevNext: true
  },
  loop: true,
  slidesPerView: 1,
  speed: 500,
  touchRatio: 0
};

export default class Fluxogram {
  constructor({ selector, options }) {
    this.swiper = new Swiper(selector, { ...DEFAULT_OPTIONS, ...options });
    this.fluxogram = window.document.querySelector(
      '[data-fluxogram-animating]'
    );
    window.document.addEventListener(
      'visibilitychange',
      this.watchTabVisibility.bind(this)
    );
  }

  restartAnimation() {
    this.fluxogram.dataset.fluxogramAnimating = true;
    this.swiper.autoplay.start();
  }

  stopAnimation() {
    this.fluxogram.dataset.fluxogramAnimating = false;
    this.swiper.slideTo(1);
    this.swiper.autoplay.stop();
  }

  // ref: https://greensock.com/forums/topic/9059-cross-browser-to-detect-tab-or-window-is-active-so-animations-stay-in-sync-using-html5-visibility-api/
  //      https://github.com/facebook/react/issues/7142
  watchTabVisibility() {
    if (window.document.visibilityState === 'visible')
      requestAnimationFrame(() => this.restartAnimation());
    else this.stopAnimation();
  }
}
