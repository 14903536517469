export default class Toggle {
  constructor({ toggle, elements, onToggle = () => {} }) {
    this.toggle = toggle;
    this.elements = elements;

    this.toggle.onclick = () => {
      this._updateCurrentStatus.bind(this)();
      this._toggleClicker.bind(this)();
      this._toggleElements.bind(this)();

      onToggle(this.currentStatus);
    };
  }

  _updateCurrentStatus() {
    const firstElement = this.elements[0];
    const actualStatus = firstElement.dataset.active || false;
    const nextStatus = !JSON.parse(actualStatus);

    this.currentStatus = nextStatus;
  }

  _toggleElements() {
    this.elements.forEach(element => {
      element.dataset.active = this.currentStatus;
    });
  }

  _toggleClicker() {
    this.toggle.dataset.active = this.currentStatus;
  }
}
